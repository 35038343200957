export function isError(instance: unknown): instance is Error {
	if (instance instanceof Error) {
		return true;
	}

	const obj = instance as Unknown<Error>;

	// For errors thrown via iframes
	return obj != null
		&& typeof obj === "object"
		&& typeof obj.name === "string"
		&& typeof obj.message === "string";
}

export function isNotNull<T>(value: T | null): value is T {
	return value !== null;
}
