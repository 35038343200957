export interface IHostEnvironment {
	environment: string;
}

export function getHostEnvironment() {
	const element = document.getElementById("host-environment");

	return element?.textContent != null ?
		JSON.parse(element.textContent) as IHostEnvironment
		: null;
}

export function isDevelopment() {
	const hostEnvironment = getHostEnvironment();

	return hostEnvironment?.environment === "Development"
		|| document.cookie.includes("development=1");
}
