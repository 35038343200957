declare const debugging: boolean | undefined;

export function isDebugging() {
	return typeof debugging !== "undefined" && debugging
		|| document.cookie.includes("debugging");
}

if (!isDebugging()) {
	console.debug = function () { /* noop */ };
}
