﻿import { isDevelopment } from "./Environment";
import { isError } from "./TypeGuards";

const headers = new Headers();
headers.append("Content-Type", "application/json");

const reportedErrors: string[] = [];
const reportEndpoint = "https://log.steamcore.se/js";

interface IScriptError extends Error {
	columnNumber: number;
	fileName: string;
	lineNumber: number;
}

function isScriptError(instance: unknown): instance is IScriptError {
	if (isError(instance)) {
		const obj = instance as IScriptError;

		return typeof obj.columnNumber === "number"
			&& typeof obj.fileName === "string"
			&& typeof obj.lineNumber === "number";
	}

	return false;
}

async function getUserAgentData() {
	if (!navigator.userAgentData) {
		return undefined;
	}

	let platform: string | undefined = undefined;
	let uaFullVersion: string | undefined = undefined;

	try {
		const data = await navigator.userAgentData.getHighEntropyValues(["platform", "uaFullVersion"]);

		platform = data.platform;
		uaFullVersion = data.uaFullVersion;
	} catch {
		/* do nothing */
	}

	return {
		brands: navigator.userAgentData.brands,
		mobile: navigator.userAgentData.mobile,
		platform,
		uaFullVersion
	};
}

export async function reportError(error: Error | string, src?: string, line?: number, column?: number, stack?: string) {
	const content = {
		column,
		error,
		line,
		src,
		stack,
		url: window.location.href,
		userAgent: navigator.userAgent,
		userAgentData: await getUserAgentData()
	};

	const body = JSON.stringify(content);
	console.debug("reportError", content);

	if (isDevelopment()) {
		return;
	}

	if (reportedErrors.findIndex(err => err === body) === -1) {
		reportedErrors.push(body);
		await fetch(reportEndpoint, { body, headers, method: "POST", mode: "cors" });
	}
}

window.addEventListener("error", e => {
	void reportError(e.message, e.filename, e.lineno, e.colno, isError(e.error) ? e.error.stack : undefined);
});

window.addEventListener("unhandledrejection", e => {
	if (isError(e.reason)) {
		const error = e.reason;
		if (isScriptError(error)) {
			void reportError(error.message, error.fileName, error.lineNumber, error.columnNumber, error.stack);
		} else {
			void reportError(error.message, undefined, undefined, undefined, error.stack);
		}
	} else if (typeof e.reason === "string") {
		void reportError(e.reason);
	}
});
